import selectric from 'selectric';

//select
$('.select').selectric({
  maxHeight: 220,
  disableOnMobile : false,
  
  onChange: function(event) {
    $(event).parents('.selectric-wrapper').addClass('active');
  },
  onRefresh: function(event) {
    $(event).parents('.selectric-wrapper').addClass('active');;
  }, 
});



//tabs
$('ul.tabs__caption').on('click', 'li:not(.active)', function() {
  $(this).addClass('active').siblings().removeClass('active')
    .closest('div.tabs').find('div.tabs__content').removeClass('active').eq($(this).index()).addClass('active');
});




//mobile-menu
setTimeout(function() {
  $('.mobile-menu__body .parent').children('a').addClass('active');
}, 1000);

$('.mobile-menu__body a').on('click', function(event) {
  if($(this).next().is('ul')) {
    event.preventDefault();
    $(this).toggleClass('click');
    $(this).next('ul').slideToggle();
  }
});

//open mobile menu
$('.menu-open').on('click', function() {
  $('.mobile-menu').addClass('active');
});
//close
$('.menu-close').on('click', function() {
  $('.mobile-menu').removeClass('active');
});




// //header
var mywindow = $(window);
var mypos = mywindow.scrollTop();
var up = false;
var newscroll;
var topStrat;
let num = 0;

mywindow.scroll(function() {
  if(mywindow.scrollTop() >= 100) {
    newscroll = mywindow.scrollTop();

    if (newscroll > mypos && !up) {
      topStrat = newscroll;
      up = !up;
    } else if(newscroll < mypos && up) {
      topStrat = newscroll;
      up = !up;
    }


    if (newscroll > mypos) {
      if(num <= 300) {
        $('.header').css({'top': -num});
        num = num + 5;
      }

    } else if(newscroll < mypos && num === 305) {
      if(newscroll - topStrat <= -100) {
        if(num > 0) {
          $('.header').css({'top': -num});
          num = num - 5;
        }
      }
    } else{
      if(num > 0) {
        $('.header').css({'top': -num});
        num = num - 5;
      }
    }
    mypos = newscroll;
  }else{
    $('.header').css({'top': 0});
    num = 0;
  }
});

//search
$('.header__btn__search button').on('click', function() {
  $(this).closest('.header__btn__search').toggleClass('active');
  if($(this).closest('.header__btn__search').hasClass('active')) {
    $(this).find('.icon-search').fadeOut(300);
    setTimeout(function() {
      $('.header__btn__search').find('.icon-close').fadeIn();
    }, 300);
    console.log('1');
  } else{
    $(this).find('.icon-close').fadeOut(300);
    setTimeout(function() {
      $('.header__btn__search').find('.icon-search').fadeIn();
    }, 300);
    console.log('2');
  }
});

$(document).mouseup(function(e) { // событие клика по веб-документу
  var div = $('.header__btn__search'); // тут указываем ID элемента
  if (!div.is(e.target) // если клик был не по нашему блоку
      && div.has(e.target).length === 0) { // и не по его дочерним элементам
    div.removeClass('active'); // скрываем его
    div.find('.icon-close').fadeOut(300);
    setTimeout(function() {
      div.find('.icon-search').fadeIn();
    }, 300);
  }
});


//dropdown
setTimeout(function() {
  $('.header__nav li .parent').children('a').addClass('active');
}, 500);

$('.header__nav li').hover(function() {
  $(this).toggleClass('active');
});

$('.header__nav li').hover(function() {
  clearTimeout($.data(this,'timer'));
  $(this).children('ul').stop(true,true).fadeIn();
}, function() {
  $.data(this,'timer', setTimeout($.proxy(function() {
    $('ul',this).stop(true,true).fadeOut();
  }, this), 100));
});

//button hide
$('input, textarea').on('focus', function() {
  $('.button-fixed, .BeaconFabButtonFrame').fadeOut();
});
$('input, textarea').on('blur', function() {
  setTimeout(function() {
    if($('input, textarea').is(':focus')) {
    } else{
      $('.button-fixed, .BeaconFabButtonFrame').fadeIn();
    }
  }, 1000);
});



//button-fixed
//open
$('.button-fixed__covid button.open').on('click', function() {
  $(this).parent().addClass('active');
  $(this).parent().find('img').fadeOut();
});

//close
$('.button-fixed__covid button.close').on('click', function() {
  $(this).parents('.button-fixed__covid').removeClass('active');
  $(this).parents('.button-fixed__covid').find('img').fadeIn();
  localStorage.setItem('hide', 1);
});
//localStorage
if(localStorage.getItem('hide') === null) {
  localStorage.setItem('hide', 0);
}
if(localStorage.getItem('hide') === '0') {
  $('.button-fixed__covid').addClass('active');
  $('.button-fixed__covid').find('img').fadeOut();
}






//form
$('.bfQuickMode select').wrap('<div class="select-custom"></div>');
$('.bfQuickMode .select-custom').append('<span>▾</span>');
