var json2 = {
  'one': [
    {
      'lesson1': [
        {
          'min': '30',
          'coin': 80
        },
        {
          'min': '45',
          'coin': 115
        },
        {
          'min': '60',
          'coin': 125
        },
        {
          'min': '90',
          'coin': 185
        },
        {
          'min': '120',
          'coin': 240
        }
      ],
      'lesson2': [
        {
          'min': '30',
          'coin': 70
        },
        {
          'min': '45',
          'coin': 100
        },
        {
          'min': '60',
          'coin': 110
        },
        {
          'min': '90',
          'coin': 160
        },
        {
          'min': '120',
          'coin': 210
        }
      ]
    }
  ],
  'group': [
    {
      'lesson1': [
        {
          'student2': [
            {
              'min': '30',
              'coin': 55
            },
            {
              'min': '45',
              'coin': 80
            },
            {
              'min': '60',
              'coin': 90
            }
          ],
          'student3': [
            {
              'min': '30',
              'coin': 50
            },
            {
              'min': '45',
              'coin': 70
            },
            {
              'min': '60',
              'coin': 75
            }
          ],
          'student4': [
            {
              'min': '30',
              'coin': 45
            },
            {
              'min': '45',
              'coin': 60
            },
            {
              'min': '60',
              'coin': 65
            }
          ]
        }
      ],
      'lesson2': [
        {
          'student2': [
            {
              'min': '30',
              'coin': 50
            },
            {
              'min': '45',
              'coin': 75
            },
            {
              'min': '60',
              'coin': 80
            }
          ],
          'student3': [
            {
              'min': '30',
              'coin': 45
            },
            {
              'min': '45',
              'coin': 65
            },
            {
              'min': '60',
              'coin': 65
            }
          ],
          'student4': [
            {
              'min': '30',
              'coin': 40
            },
            {
              'min': '45',
              'coin': 55
            },
            {
              'min': '60',
              'coin': 55
            }
          ]
        }
      ]
    }
  ]
};


$('.cost-select-1').selectric({
  onChange: function(event) {
    lessenCost1(event);
  },
  onRefresh: function(event) {
    lessenCost1(event);
  }, 
});
lessenCost1($('.cost-select-1'));
lessenCost2($('.cost-select-2'));


$('.cost-select-2').selectric({
  onChange: function(event) {
    lessenCost2(event);
  },
  onRefresh: function(event) {
    lessenCost2(event);
  }, 
});


function lessenCost1(event) {
  let select = $(event).val();
  if(+select === 2) {
    $('.cost-select-1-30').text('$' + json2.group[0].lesson1[0].student2[0].coin);
    $('.cost-select-1-45').text('$' + json2.group[0].lesson1[0].student2[1].coin);
    $('.cost-select-1-60').text('$' + json2.group[0].lesson1[0].student2[2].coin);
  } else if(+select === 3) {
    $('.cost-select-1-30').text('$' + json2.group[0].lesson1[0].student3[0].coin);
    $('.cost-select-1-45').text('$' + json2.group[0].lesson1[0].student3[1].coin);
    $('.cost-select-1-60').text('$' + json2.group[0].lesson1[0].student3[2].coin);
  } else if(+select === 4) {
    $('.cost-select-1-30').text('$' + json2.group[0].lesson1[0].student4[0].coin);
    $('.cost-select-1-45').text('$' + json2.group[0].lesson1[0].student4[1].coin);
    $('.cost-select-1-60').text('$' + json2.group[0].lesson1[0].student4[2].coin);
  }

}

function lessenCost2(event) {
  let select = $(event).val();
  if(+select === 2) {
    $('.cost-select-2-30').text('$' + json2.group[0].lesson2[0].student2[0].coin);
    $('.cost-select-2-45').text('$' + json2.group[0].lesson2[0].student2[1].coin);
    $('.cost-select-2-60').text('$' + json2.group[0].lesson2[0].student2[2].coin);
  } else if(+select === 3) {
    $('.cost-select-2-30').text('$' + json2.group[0].lesson2[0].student3[0].coin);
    $('.cost-select-2-45').text('$' + json2.group[0].lesson2[0].student3[1].coin);
    $('.cost-select-2-60').text('$' + json2.group[0].lesson2[0].student3[2].coin);
  } else if(+select === 4) {
    $('.cost-select-2-30').text('$' + json2.group[0].lesson2[0].student4[0].coin);
    $('.cost-select-2-45').text('$' + json2.group[0].lesson2[0].student4[1].coin);
    $('.cost-select-2-60').text('$' + json2.group[0].lesson2[0].student4[2].coin);
  }
}


