var json2 = {
  'one': [
    {
      'lesson1': [
        {
          'min': '30',
          'coin': 80
        },
        {
          'min': '45',
          'coin': 115
        },
        {
          'min': '60',
          'coin': 125
        },
        {
          'min': '90',
          'coin': 185
        },
        {
          'min': '120',
          'coin': 240
        }
      ],
      'lesson2': [
        {
          'min': '30',
          'coin': 70
        },
        {
          'min': '45',
          'coin': 100
        },
        {
          'min': '60',
          'coin': 110
        },
        {
          'min': '90',
          'coin': 160
        },
        {
          'min': '120',
          'coin': 210
        }
      ]
    }
  ],
  'group': [
    {
      'lesson1': [
        {
          'student2': [
            {
              'min': '30',
              'coin': 55
            },
            {
              'min': '45',
              'coin': 80
            },
            {
              'min': '60',
              'coin': 90
            }
          ],
          'student3': [
            {
              'min': '30',
              'coin': 50
            },
            {
              'min': '45',
              'coin': 70
            },
            {
              'min': '60',
              'coin': 75
            }
          ],
          'student4': [
            {
              'min': '30',
              'coin': 45
            },
            {
              'min': '45',
              'coin': 60
            },
            {
              'min': '60',
              'coin': 65
            }
          ]
        }
      ],
      'lesson2': [
        {
          'student2': [
            {
              'min': '30',
              'coin': 50
            },
            {
              'min': '45',
              'coin': 75
            },
            {
              'min': '60',
              'coin': 80
            }
          ],
          'student3': [
            {
              'min': '30',
              'coin': 45
            },
            {
              'min': '45',
              'coin': 65
            },
            {
              'min': '60',
              'coin': 65
            }
          ],
          'student4': [
            {
              'min': '30',
              'coin': 40
            },
            {
              'min': '45',
              'coin': 55
            },
            {
              'min': '60',
              'coin': 55
            }
          ]
        }
      ]
    }
  ]
};
//one
$('.calculator-select-one').selectric({
  onChange: function(event) {
    oneCostLesson(event);
  },
  onRefresh: function(event) {
    oneCostLesson(event);
  }, 
});
// $(document).ready(function() {
//   oneCostLesson();
// });

function oneCostLesson(event) {
  
  let oneStudents = $('.one-students').val();
  let oneLesson = $('.one-lesson').val();
  let oneMinutes = $('.one-minutes').val();
  if(oneStudents > 0 && oneLesson > 0 && oneMinutes > 0) {
    //Total Number of Lessons
    setTimeout(function() {
      if(oneLesson >= 12 && oneLesson < 22) {
        $('.one-lesson-result').text(+oneLesson + 1);
      } else if(oneLesson >= 22) {
        $('.one-lesson-result').text(+oneLesson + 3);
      } else{
        $('.one-lesson-result').text(oneLesson);
      }
    }, 10);

    //Cost Per Lesson
    // $.getJSON('/js/cost.json', function(data) {
    if(oneLesson <= 5) {
      $('.one-lesson-fact').text(json2.one[0].lesson1[+oneMinutes - 1].coin);
    } else{
      $('.one-lesson-fact').text(json2.one[0].lesson2[+oneMinutes - 1].coin);
    }
    setTimeout(function() {
      let CostPerLesson1 = (($('.one-lesson-fact').text() * $('.one-lesson').val()) / $('.one-lesson-result').text());
      $('.one-lesson-coin').text( Math.round(CostPerLesson1 * 100) / 100);
    }, 100);

    //Cost Per Student
    setTimeout(function() {
      let CostPerStudent = (($('.one-lesson-fact').text() * $('.one-lesson').val()) / $('.one-lesson-result').text()) / $('.one-students').val();
      $('.one-lesson-student').text( Math.round(CostPerStudent * 100) / 100);
    }, 100);
  
    //total
    setTimeout(function() {
      $('.one-lesson-total').text($('.one-lesson-fact').text() * $('.one-lesson').val());
    }, 100);
  } else{
    $('.one-lesson-fact').text('0');
    $('.one-lesson-result').text('0');
    $('.one-lesson-coin').text('0');
    $('.one-lesson-student').text('0');
    $('.one-lesson-total').text('0');
  }
  
}







//group
$('.calculator-select-group').selectric({
  onChange: function(event) {
    let select = event;
    groupCostLesson(select);
  },
  onRefresh: function(event) {
    let select2 = event;
    groupCostLesson(select2);
  }, 
});
// setTimeout(function() {
//   groupCostLesson();
// }, 100);
// $(document).ready(function() {
//   groupCostLesson();
// });




function groupCostLesson(event) {
  let groupStudents = $('.group-students').val();
  let groupLesson = $('.group-lesson').val();
  let groupMinutes = $('.group-minutes').val();
  if(groupStudents > 0 && groupLesson > 0 && groupMinutes > 0) {
  //Total Number of Lessons
    setTimeout(function() {
      if(groupLesson >= 12 && groupLesson < 22) {
        $('.group-lesson-result').text(+groupLesson + 1);
      } else if(groupLesson >= 22) {
        $('.group-lesson-result').text(+groupLesson + 3);
      } else{
        $('.group-lesson-result').text(groupLesson);
      }
    }, 100);






    //Cost Per Student Per Lesson

    // $.getJSON('/js/cost.json', function(data) {
  
    if(groupLesson <= 5) {
      if(+groupStudents === 2) {
        $('.group-lesson-fact').text(json2.group[0].lesson1[0].student2[+groupMinutes - 1].coin);
      } else if(+groupStudents === 3) {
        $('.group-lesson-fact').text(json2.group[0].lesson1[0].student3[+groupMinutes - 1].coin);
      } else if(+groupStudents === 4) {
        $('.group-lesson-fact').text(json2.group[0].lesson1[0].student4[+groupMinutes - 1].coin);
      }
    } else{
      if(+groupStudents === 2) {
        $('.group-lesson-fact').text(json2.group[0].lesson2[0].student2[+groupMinutes - 1].coin);
      } else if(+groupStudents === 3) {
        $('.group-lesson-fact').text(json2.group[0].lesson2[0].student3[+groupMinutes - 1].coin);
      } else if(+groupStudents === 4) {
        $('.group-lesson-fact').text(json2.group[0].lesson2[0].student4[+groupMinutes - 1].coin);
      }
    }
    setTimeout(function() {
      let CostPerLesson = $('.group-lesson-fact').text() * +groupLesson * +groupStudents / $('.group-lesson-result').text() / +groupStudents;
      $('.group-lesson-student').text( Math.round(CostPerLesson * 100) / 100);
    }, 100);
  
    //Cost Per Lesson
    setTimeout(function() {
      let CostPerLesson = $('.group-lesson-fact').text() * +groupLesson * +groupStudents / $('.group-lesson-result').text();
      $('.group-lesson-coin').text( Math.round(CostPerLesson * 100) / 100);
    }, 100);


    //total
    setTimeout(function() {
      $('.group-lesson-total').text($('.group-lesson-fact').text() * +groupLesson * +groupStudents);
    }, 100);

  } else{
    $('.group-lesson-result').text('0');
    $('.group-lesson-coin').text('0');
    $('.group-lesson-student').text('0');
    $('.group-lesson-fact').text('0');
    $('.group-lesson-total').text('0');

  }
}






//question
$('.question .open').on('click', function(event) {
  event.preventDefault();
  $(this).next().fadeToggle();
});
$('.question .close').on('click', function(event) {
  event.preventDefault();
  $(this).parent().fadeToggle();
});




