import './import/swiper';
import './import/ui';
import './import/calculator';
import './import/cost';
import objectFitImages from 'object-fit-images';
import './lib/webp';



objectFitImages();
