import Swiper from 'swiper';



var swiper = new Swiper('.swiper-cost', {
  spaceBetween: 30,
  autoHeight: true,
  navigation: {
    nextEl: '.swiper-cost-next',
    prevEl: '.swiper-cost-prev',
  }
});
